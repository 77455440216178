import React, { memo, useEffect, useMemo } from "react";
import {
  PRIVATE_PAGES,
  PUBLIC_LINKS,
  PUBLIC_PAGES,
} from "../../../constants/navigation-routes";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import MiniDrawer from "../../../layouts/MiniDrawer";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoThunk, userActions } from "../../../redux/userSlice";
import SplashScreen from "../../../pages/splash";

const DynamicRoutes = () => {
  const { isAuthenticated, isLoading} = useAuth0();

  console.log(isAuthenticated);

  const navigate = useNavigate();
  const userMetaData = useSelector((state) => state.user.userMetaData);
  const userinfo = useSelector((state) => state.user.userinfo);
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    // const temp = {
    //   success: false,
    //   active: false,
    // };

    if (userMetaData?.success === false && userMetaData?.active === false)
      navigate("/error", {
        state: {
          title1: "Something went wrong",
          title2:
            "Something bad happened. Please contact application administrator at anshul@devneural.com to resolve this issue.",
          buttonTitle: "TALK TO SUPPORT",
          buttonLink: "https://calendly.com/engranshul/30min",
        },
      });

    if (userMetaData?.success === false && userMetaData?.active === true)
      navigate("/error", {
        state: {
          title1: "Something went wrong",
          title2:
            "something bad happened..please contact application administrator at anshul@devneural.com to resolve issue.",
          buttonTitle: "TALK TO SUPPORT",
          buttonLink: "https://calendly.com/engranshul/30min",
        },
      });

    if (userMetaData?.success === true && userMetaData?.active === false)
      navigate("/error", {
        state: {
          title1: "Account not active",
          title2:
            "It appears account is not active. If you'd like to continue using Prompt Channel or request a trial extension, please contact us below.",
          buttonTitle: "TALK TO SUPPORT",
          buttonLink: "https://calendly.com/engranshul/30min",
        },
      });

    if (
      userMetaData &&
      (userMetaData?.success !== true || userMetaData?.active !== true)
    ) {
      dispatch(userActions.toggleLoading(false));
    }

    if (userMetaData?.success === true && userMetaData?.active === true) {
      dispatch(getUserInfoThunk());
    }

    // if user is not authenticated and userMetaData is null then navigate to default page
    if (!isLoading && !isAuthenticated && userMetaData === null) {
      dispatch(userActions.toggleLoading(false));
      //   navigate(PUBLIC_LINKS.DefaultPage.pageLink);
    }
  }, [userMetaData, isLoading, isAuthenticated, loading]);

  // const permission=permissionsConverter(permissions.permissions);

  // let permission =[];
  // if(isAuthenticated && userinfo?.user_id)permission = permissionsConverter('[\"all:testsuite\",\"all:others\"]');

  let permission = useMemo(() => {
    let rawPermission = [];
    userinfo?.app_metadata?.organisations[
      userinfo?.active_organisation_id
    ]?.teams[userinfo?.active_team_id]?.workspaces[
      userinfo?.active_workspace_id
    ]?.effective_permissions.forEach((item) => {
      rawPermission.push(item);
    });

    rawPermission.push("all:others");
    if (isAuthenticated && userinfo?.user_id) {
      return rawPermission;
    }
    return [];
  }, [isAuthenticated, userinfo]);

  if (loading) {
    return <SplashScreen />;
  }

  // let permission= permissionsConverter("[\"all:datastore\",\"all:others\",\"all:settings\",\"all:studio\",\"all:testsuite\"]");

  return (
    <Routes>
      {PUBLIC_PAGES.map((item, i) => (
        <Route key={i} path={item.pageLink} element={<item.view />} />
      ))}

      {permission?.map((str, _) => {
        const result = str.split(/:|\s+/);
        // if(isAuthenticated && (result[0] === 'none' || PRIVATE_PAGES[result[1]] === undefined)){
        //     return null;
        // }
        return PRIVATE_PAGES[result[1]].map((item, i) => {
          return item.loginRequire ? (
            <Route
              key={i}
              path={item.pageLink}
              element={
                isAuthenticated ? (
                  <MiniDrawer>
                    <item.view />
                  </MiniDrawer>
                ) : (
                  <Navigate to={PUBLIC_LINKS.DefaultPage.pageLink} />
                )
              }
            />
          ) : (
            <Route key={i} path={item.pageLink} element={<item.view />} />
          );
        });
      })}
    </Routes>
  );
};

export default memo(DynamicRoutes);
