// eslint-disable
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { HelpCenter, Logout, Settings } from "@mui/icons-material";
import AuthContext from "../context/AuthContext";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ALL_LINKS } from "../constants/navigation-routes";
import { useAuth0 } from "@auth0/auth0-react";
import { MENU_BUTTONS } from "../constants/navigation-buttons";
import { MenuItem, Select, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../redux/userSlice";
import { assets } from "../assets/assets";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const authCtx = React.useContext(AuthContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const themeMode = useSelector((state) => state.user.themeMode);
  const themeColor = useSelector((state) => state.user.themeColor);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [currentUserButtons, setCurrentUserButtons] = React.useState([]);

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes("/settings")) {
      setCurrentUserButtons([
        MENU_BUTTONS.HOME,
        MENU_BUTTONS.GENERAL,
        MENU_BUTTONS.APIKEYS,
        MENU_BUTTONS.PROVIDERS,
        MENU_BUTTONS.ORGANISATION_MANAGEMENT,
      ]);
    } else {
      if (authCtx.role === "admin" || authCtx.role === "user") {
        setCurrentUserButtons([
          MENU_BUTTONS.HOME,
          MENU_BUTTONS.WORKSPACES,
          MENU_BUTTONS.TEMPLATES,
          MENU_BUTTONS.DOCUMENTS,
          MENU_BUTTONS.TEST_SUITE,
          MENU_BUTTONS.PLAYGROUND,
          MENU_BUTTONS.DEPLOYMENTS,
          MENU_BUTTONS.WORKFLOWS
        ]);
      } else if (authCtx.role === "playground_tester") {
        setCurrentUserButtons([MENU_BUTTONS.HOME, MENU_BUTTONS.PLAYGROUND]);
      } else if (authCtx.role === "suite_tester") {
        setCurrentUserButtons([MENU_BUTTONS.HOME, MENU_BUTTONS.TEST_SUITE]);
      }
    }
  }, [authCtx.role, location.pathname]);

  const { logout, user } = useAuth0();

  const DrawerButton = ({ title, icon, pageLink, target = "_self" }) => {
    return (
      <NavLink
        target={target}
        className={({ isActive }) =>
          `rounded-full overflow-hidden ${
            isActive
              ? "bg-primary500 rounded-full text-primary900 text-white flex"
              : ""
          }`
        }
        to={pageLink}
      >
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              borderRadius: "50px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: "inherit",
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </NavLink>
    );
  };

  return (
    <div className="flex">
     {/*<AppBar position="fixed" open={open}>
         <Toolbar className="bg-primary50 shadow-none">
          <div>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon className="" />
            </IconButton>
          </div>
          <div className="flex justify-between w-full items-center">
            <Link to={"/"} className="text-xl ml-8 text-textColor font-bold">
              <div className="relative">
                <h1 className="text-2xl font-bold">LLMS</h1>
                <span className="text-xs text-white bg-gradient-to-tr from-orange-500 to-red-300 py-1 px-2 rounded-full absolute top-[-8px] right-[-48px] font-bold">
                  BETA
                </span>
              </div>
            </Link>

            <div className="flex gap-4 relative">
              <div className="group/item ">
                {user?.picture ? (
                  <img
                    src={user?.picture}
                    alt=""
                    className="w-12 h-12 rounded-full"
                  />
                 ) : (
                  <div className="rounded-full w-12 h-12 bg-black text-gold flex items-center justify-center uppercase font-bold text-2xl">
                    {user?.given_name ? user?.given_name[0] : user?.email[0]}
                  </div>
                )}
                <div className="group/edit hidden bg-transparent  text-textColor group-hover/item:flex absolute   right-0 top-[40px] w-[320px]">
                  <div className="bg-white rounded-xl p-4 mt-4 w-[100%]">
                  </div>
                </div>
              </div>
              <Switch 
            value={themeMode==='dark'}
            checked={themeMode==='dark'}
            onChange={()=>dispatch(userActions.setThemeMode(themeMode==='light' ? 'dark' : 'light'))} />
              <Select size='small' value={themeColor} onChange={(e)=>dispatch(userActions.setThemeColor(e.target.value))}>
              <MenuItem value={'none'}>None</MenuItem>
              <MenuItem value={'red'}>Red</MenuItem>
              <MenuItem value={'blue'}>Blue</MenuItem>
              <MenuItem value={'green'}>Green</MenuItem>
              <MenuItem value={'purple'}>Purple</MenuItem>
              <MenuItem value={'pink'}>Pink</MenuItem>
              <MenuItem value={'yellow'}>Yellow</MenuItem>
              <MenuItem value={'orange'}>Orange</MenuItem>
              <MenuItem value={'indigo'}>Indigo</MenuItem>
              <MenuItem value={'teal'}>Teal</MenuItem>
              <MenuItem value={'sky'}>Sky</MenuItem>
              <MenuItem value={'rose'}>Rose</MenuItem>
              <MenuItem value={'lime'}>Lime</MenuItem>

            </Select>
            </div>
          </div>
        </Toolbar> 
      </AppBar>*/}

      <Drawer variant="permanent" open={open}>
        <DrawerHeader className="bg-primary50">
          <IconButton onClick={()=>{
            open ? handleDrawerClose() : handleDrawerOpen()
          }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              open ? <ChevronLeftIcon /> : <MenuIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div className="flex bg-primary50 justify-between flex-col h-full px-[6px]">
          <List className="flex flex-col gap-1">
            {currentUserButtons.map((item, index) => (
              <DrawerButton
                title={item.title}
                pageLink={item.pageLink}
                icon={item.icon}
                key={index}
              />
            ))}
          </List>

          {!location.pathname.includes("/settings") && (
            <List  className="flex flex-col gap-1">
              <Divider sx={{ marginTop: 8 }} />
              <DrawerButton
                target={"_target"}
                title={"GET HELP"}
                pageLink="https://www.google.com"
                icon={<HelpCenter />}
              />
              <DrawerButton
                title={"SETTINGS"}
                pageLink={ALL_LINKS.General.pageLink}
                icon={<Settings />}
              />
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    localStorage.removeItem("role");
                    logout({
                      logoutParams: {
                        returnTo: `${window.location.origin}`,
                      },
                    });
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: "50px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Logout />
                  </ListItemIcon>
                  <ListItemText
                    primary={"LOGOUT"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}
        </div>
      </Drawer>
      <div
        className="w-full bg-primaryBg text-textColor"
        component="main"
      >
        {children}
      </div>
    </div>
  );
}
