import { useState } from "react";
import React from "react";
import { toast } from "react-toastify";
const AuthContext=React.createContext({
    role:"",
    token:"",
    userid:"",
    isLoggedIn:false,
    login: (token) => {},
    logout: () => {},
    updateRole:()=>{},
})

const retrieveStoredToken = () => {
    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userid");
    const role = localStorage.getItem("role");
    const theme=localStorage.getItem("theme");
    const themeColor=localStorage.getItem("themeColor");
  
    return { token,userid,role,theme,themeColor};
  };

export const AuthContextProvider=(props)=>
{
    const tokenData = retrieveStoredToken();
    let initialToken;
    let initialUserid;
    let initialRole;
    if (tokenData) {
      initialToken = tokenData.token;
      initialUserid=tokenData.userid;
      initialRole=tokenData.role;
    }
    const [token, setToken] = useState(initialToken);
    const [role, setRole] = useState(initialRole || 'user');
    const [userid, setUserId] = useState(initialUserid);
    const [isLoggedIn,setIsLoggedIn]=useState(!!initialToken)

    // useEffect(() => {
    //   console.log(theme)
    //     if (theme==='dark') {
    //       document.documentElement.classList.add('green');
    //     }
    //     else{
    //       document.documentElement.classList.remove('green');
    //     }

    //   }, [theme]);


    const updateRoleHandler=(role)=>{
      localStorage.setItem('role',role);
      setRole(role);
    }
    const logoutHandler = () => {
        toast.success("Logged out successfully")
        setToken(null);
        setIsLoggedIn(false);
        setUserId(null);
        setRole(null)
        localStorage.removeItem("token");
        localStorage.removeItem("userid");
        localStorage.removeItem("role");
      };

      const loginHandler = (token,userid,role) => {
        setToken(token);
        setUserId(userid);
        setIsLoggedIn(true);
        setRole(role);
        localStorage.setItem("token", token);
        localStorage.setItem("userid", userid);
        localStorage.setItem("role", role);

      };

      const tokenValue = {
        token: token,
        userid:userid,
        role:role,
        isLoggedIn: isLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        updateRole:updateRoleHandler,
      };



      return (
        <AuthContext.Provider value={tokenValue}>
          {props.children}
        </AuthContext.Provider>
      );
}

export default AuthContext